<template>
  <div class="page-qaform bg-main">
    <div class="box-tab">
      <p class="sel">新增质检反馈</p>
      <p class="" @click="$router.replace({name: 'qaList', query: q})" >质检反馈汇总</p>
    </div>
    <div class="box-intro">
      <p style="font-size: 1rem;">我们正在倾听您的意见......</p>
      <p>使用时遇到问题？或有更好的建议？欢迎您随时提出来！</p>
    </div>
    <div class="sec box-form">
      <div class="item">
        <div class="title"><span>*</span>您想对哪方面提出建议？</div>
        <el-radio-group v-model="params.type" class="group-btn">
          <el-radio v-for="item in typeList"
            :key="item.id" :label="item.id"
            border class="col2">{{item.name}}</el-radio>
        </el-radio-group>
      </div>


      <div class="item">
        <div class="title">录音时间定位（小时/分/秒）</div>
        <div class="box-time">
          <el-input v-model="time.h" :maxlength="2" placeholder="00" @input="onInput('h')"></el-input>
          <strong>:</strong>
          <el-input v-model="time.m" :maxlength="2" placeholder="00" @input="onInput('m')"></el-input>
          <strong>:</strong>
          <el-input v-model="time.s" :maxlength="2" placeholder="00" @input="onInput('s')"></el-input>
        </div>
      </div>
      <div class="item">
        <div class="title"><span>*</span>问题描述</div>
        <div class="box-qa">
          <div class="box-ta">
            <p>填写正确对话文字</p>
            <el-input clearable
              class="ta"
              type="textarea"
              resize="none"
              placeholder="请输入"
              :maxlength="100"
              :rows="5"
              :show-word-limit="true"
              v-model="params.description">
            </el-input>
          </div>
          <div class="sign">
            <img src="@/assets/i_switch.png" />
            <p>错误转写成</p>
          </div>
          <div class="box-ta">
            <p>填写转写错误文字 (如未转写出，请填“无转写”)</p>
            <el-input clearable
              class="ta"
              type="textarea"
              resize="none"
              placeholder="请输入"
              :maxlength="100"
              :rows="5"
              :show-word-limit="true"
              v-model="params.description1">
            </el-input>
          </div>
        </div>
                
      </div>
    </div>
    <div class="box-btn">
      <!--
      <span class="reset">取消</span>
      -->
      <span class="search" @click="submit">保存</span>
    </div>
</div>    
</template>

<script>
import Vue from 'vue'
import { postForm } from '@/api'
import moment from 'moment'

export default {
  data(){
    return {
      id: '',
      params: {},
      typeList: [],
      time: {},
      q: null,
    }
  },
  components:{
  },
  methods: {
    onInput (k) {
      const v = this.time[k];
      this.time[k] = v.replace(/[^\d.]/g,'');
      if (v > 59) {
        this.time[k] = '';
      }
    },
    getType() {
      postForm('/h5/feedback/getTypeList').then(res => {
        if (res.status === 1) {
          this.typeList = (res.data || [])//.slice(0,1);
          const curType = this.typeList[0] || {};
          this.params = {
            ...this.params,
            type: curType.id,
          }
        }
      })
    },
    submit() {
      if (!this.params.type) {
        this.$message.error('请选择建议类型');
        return;
      }
      if (!this.params.description) {
        this.$message.error('填写正确对话文字');
        return;
      }
      if (!this.params.description1) {
        this.$message.error('填写转写错误文字');
        return;
      }
      postForm('/h5/feedback/add', {
        ...this.params,
        task_id: this.id,
        timing: getSeconds(this.time),
      }).then(res => {
        if (res.status === 1) {
          this.$message.success('提交成功');
          this.$router.replace({name: 'qaList', query: {id: this.id}});
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
  created() {
    const q = this.$router.currentRoute.query || {};
    this.q = q;
    this.id = q.id;
    if (q.time) {
      const timeF = moment.utc(q.time*1000).format('HH:mm:ss');
      const t = timeF.split(':');
      this.time = {
        h: t[0],
        m: t[1],
        s: t[2],
      };
    }
    this.getType();
  }
  
}

function getSeconds(t = {}) {
  const time = `${t.h || 0}:${t.m || 0}:${t.s || 0}`;
  return moment.duration(time).as('seconds');
}
</script>

<style lang="less">
.page-qaform {
  position: relative;
  padding-top: 1rem;

  .box-qa {
    display: flex;
    align-items: flex-end;

    .box-ta {
      flex: 1;

      p {
        font-size: 0.6rem;
        line-height: 1.2;
        color: #666666;
      }
    }
    .sign {
      text-align: center;
      font-size: 0.5rem;
      padding: 1.5rem 0.2rem;
      color: #000;
      img {
        width: 0.8rem;
        height: 0.8rem;
        display: inline-block;
        margin-bottom: 0.2rem;
      }
    }
  }
  .box-tab {
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: space-between;
    p {
      max-width: 48%;
      display: flex;
      align-items: center;
      flex: 1;
      background: #fff;
      color: #5C5C5C;
      border-radius: 0.5rem;
      height: 2.3rem;
      justify-content: center;
      font-size: 0.7rem;
      margin: 0;
      &.sel {
        color: #409EFF;
        border: 1px solid #409EFF;
      }
    }
  }

  .box-intro {
    margin-top: 1.7rem;
    font-size: 0.65rem;
    padding: 0 1rem;
    font-weight: 400;

    p {
      margin: 0.5rem 0 0 0;
    }
  }

  .box-form {
    margin-top: 1.5rem;
    padding: 0.75rem;

    .item {
      margin-bottom: 1rem;
    }
    .title {
      font-size: 0.7rem;
      margin-bottom: 0.5rem;
      span {
        color: #ED5A54;
      }
    }

    .group-btn {
      padding-top: 0;
      margin-top: -0.5rem;
    }
    .ta {
      .el-textarea__inner {
        background: #F2F4F5;
        border: 0;
      }
      .el-input__count {
        background: transparent;
      }
    }

    .box-time {
      display: flex;
      align-items: center;
      strong {
        padding: 0 1rem;
      }
      .el-input__inner {
        flex: 1;
        border-radius: 0.2rem;
        border: 1px solid rgba(200,201,204,0.36);
        text-align: center;
        line-height: 1.5rem;
      }
    }
  }

  .box-btn {
    position: fixed;
    bottom: 0;
    width: 100%;
    max-width: 750px;
    display: flex;
    span {
      font-size: 0.8rem;
      line-height: 2.5rem;
      flex: 1;
      text-align: center;
      font-weight: 400;
      color: #969799;
      background: #fff;
      &.search {
        color: #fff;
        background: #377EEA;
      }
    }
  }
}

</style>
